type Props = {
  className?: string;
};

export const Logo = ({ className }: Props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 200 200"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      d="M59.3903 72.267C59.3903 72.267 116.249 13.3141 127.348 2.42409C132.584 -2.81151 139.495 0.748699 135.621 10.0681C129.338 25.2513 117.924 57.8168 117.924 57.8168C117.924 57.8168 107.139 81.2723 125.882 90.5916C144.626 99.911 165.464 99.911 142.846 125.356C120.123 150.696 82.8458 189.126 80.0186 191.848C65.3589 206.403 55.2018 200.853 64.5212 180.225C80.5421 144.414 83.2646 129.963 83.2646 129.963C83.2646 129.963 86.6154 114.047 68.7097 106.089C50.9086 98.1309 37.5055 94.1518 59.2856 72.3717"
      fill="currentColor"
    />
  </svg>
);
